<template>
  <section class="d-flex flex-column align-items-center">
    <div class="d-flex flex-column align-items-center my-20">
      <v-avatar size="96">
        <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="
          Profile.profilePicture != null && Profile.profilePicture != ''
            ? Profile.profilePicture
            : require('@/assets/images/profile.jpg')
        "></v-img>
      </v-avatar>
      <span class="f14 font-weight-bold ">
        {{ Profile.firstName + " " + Profile.lastName }}
      </span>

      <span class="my-1 f13 left-to-right">
        {{ Profile.mobileNumber }}
      </span>
    </div>
    <v-card class="w-100 white rounded-t-xl">
      <div class="sheet-handle w-100 text-center">
        <span></span>
        <span></span>
      </div>
      <v-list class="w-100 pt-0 mt-auto" light shaped>
        <v-list-item-group color="primary">
          <!--   ripple @click="inactiveToast"       -->
          <v-list-item link ripple to="/c/appointments" href="/c/appointments" >
            <v-list-item-icon>
              <v-sheet color="#eee8ff" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <i class="fal fa-calendar-alt fa-lg" style="color: #20b1ea"></i>
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Appointment') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/c/exams" href="/c/exams">
            <v-list-item-icon>
              <v-sheet color="#f0f7ff" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <i class="fal fa-book-open fa-lg" style="color: #20b1ea"></i>
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Exams') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/c/exercises" href="/c/exercises">
            <v-list-item-icon>
              <v-sheet color="#fff3e1" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <i class="fal fa-clipboard-list fa-lg" style="color: #f8b758"></i>
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Exercises') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/c/profile/wallet" href="/c/profile/wallet">
            <v-list-item-icon>
              <v-sheet color="#e4ecff" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="tone" name="wallet" color="#91b2ff" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Wallet') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/c/profile/questions" href="/c/profile/questions">
            <v-list-item-icon>
              <v-sheet color="#feebdd" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <i class="fal fa-question-circle fa-lg" style="color: #faa365"></i>
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Questions and answers') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/c/moods" href="/c/moods">
            <v-list-item-icon>
              <v-sheet color="#ecfff4" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <i class="fal fa-chart-line fa-lg" style="color: #25ac71"></i>
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Mood status') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/c/profile/favorites" href="/c/profile/favorites">
            <v-list-item-icon>
              <v-sheet color="#fff9e4" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="light" name="bookmark" color="#F9A825" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Favorites') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item link ripple to="/c/profile/setting" href="/c/profile/setting">
            <v-list-item-icon>
              <v-sheet color="#fbe9e7" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="light" name="setting" color="#ff5722" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Setting') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple to="/c/profile/edit" href="/c/profile/edit">
            <v-list-item-icon>
              <v-sheet color="#e8ffff" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="light" name="profile" color="#00d0d4" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Edit profile') }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <iconly type="light" name="arrow-left2" color="#757575" />
            </v-list-item-action>
          </v-list-item>

          <v-list-item link ripple href="https://mindology.io/about-us" target="_blank">
            <v-list-item-icon>
              <v-sheet color="#E8EAF6" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <iconly type="light" name="infocircle" color="#5C6BC0" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('About us') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link ripple href="https://mindology.io/contact-us" target="_blank">
            <v-list-item-icon>
              <v-sheet color="#F1F8E9" elevation="0" height="36" width="36"
                class="d-flex align-items-center justify-content-center rounded-lg">
                <!-- #689F38 -->
                <iconly type="light" name="calling" color="#689F38" />
              </v-sheet>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold f15">{{ $t('Contact us') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>


          <v-list-item>
            <v-btn color="accent" block rounded large class="my-2" @click="logout">
              {{ $t('Log out') }}
              <iconly type="bulk" name="logout" color="#FFFFFF" class="ms-2" />
            </v-btn>
          </v-list-item>
          
          <span class="text-center d-block w-100 fw-bold f14 grey--text text--darken-3">v{{version}}</span>

        </v-list-item-group>
      </v-list>
    </v-card>
  </section>
</template>
<script>
import apiService from "@/http/ApiService.js";
import {version} from '@/../package.json';

export default {
  data() {
    return {
      version,

      Profile: {
        firstName: "",
        lastName: "",
      },
    };
  },
  methods: {
    inactiveToast(){
      this.$toast.info(this.$t("This section is temporarily inactive"), { rtl: this.$vuetify.rtl });
    },
    logout() {
      this.$swal({
        icon: "warning",
        showDenyButton: true,
        text: this.$t('Do you want to log out?'),
        confirmButtonText: this.$t('Yes'),
        denyButtonText: this.$t('No'),
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("logout");
            this.$router.push("/auth");
          }
        });
    },
    getUserInformationByPhoneNumber(mobileNumber) {
      apiService
        .getUserInformationByPhoneNumber(mobileNumber)
        .then((DATA) => {
          this.Profile = DATA.data;
        })
        .catch((err) => {
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          })
            .then((result) => {
              if (result.isConfirmed) {
                this.$router.go(-1);
              }
            });

        });
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.$vuetify.theme.dark = true;
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    this.getUserInformationByPhoneNumber(this.$store.getters.mobileNumber);
  },
  beforeDestroy() {
    this.$vuetify.theme.dark = false;
  }
};
</script>